/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{ref:"cardBody",on:{"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();}}},[_c(VCardTitle,[_vm._v("Bild hochladen")]),_c(VCardText,[_c('input',{ref:"FileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onFileSelect}}),(_vm.selectedFile)?_c(VBtn,{staticClass:"mb-1",on:{"click":function($event){return _vm.$refs.FileInput.click()}}},[_vm._v("Anderes Bild auswählen")]):_vm._e(),(!_vm.selectedFile)?[_c('div',{staticClass:"dropZone",on:{"click":function($event){return _vm.$refs.FileInput.click()}}},[_c(VIcon,[_vm._v("fa-upload")]),_c('span',{staticClass:"ml-2"},[_vm._v("Ziehen Sie ein Bild hierher oder klicken Sie um eine Datei auszuwählen.")])],1)]:_vm._e(),_c('VueCropper',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedFile),expression:"selectedFile"}],ref:"cropper",attrs:{"src":_vm.selectedFile,"viewMode":1,"autoCropArea":1,"minCropBoxWidth":80,"minCropBoxHeight":80}})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Abbrechen")]),_c(VBtn,{attrs:{"color":"primary","disabled":false,"loading":_vm.loading},on:{"click":_vm.saveImage}},[_vm._v("Hochladen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }