"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsPraxisColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
const map = {
    Montag: 1,
    Dienstag: 2,
    Mittwoch: 3,
    Donnerstag: 4,
    Freitag: 5,
    Samstag: 6,
    Sonntag: 7,
};
function settingsPraxisColumnsDefs() {
    return [
        {
            headerName: 'Wochentag',
            field: 'tagName',
            comparator: (valueA, valueB) => map[valueA] - map[valueB],
            sortable: true,
            sort: 'asc',
            flex: 1,
        },
        {
            headerName: 'Von',
            field: 'von',
            sortable: true,
            valueFormatter: params => { var _a; return (params.data ? `${common_ui_1.uhrFilter.filters.uhr((_a = params.data) === null || _a === void 0 ? void 0 : _a.von)}` : ''); },
            width: 80,
        },
        {
            headerName: 'Bis',
            field: 'bis',
            sortable: true,
            valueFormatter: params => (params.data ? `${common_ui_1.uhrFilter.filters.uhr(params.data.bis)}` : ''),
            width: 150,
        },
        {
            headerName: 'Öffnungsdauer',
            field: 'stunden',
            sortable: true,
            cellStyle: { justifyContent: 'center' },
            valueFormatter: params => (params.data ? `${params.data.stunden} h` : ''),
            width: 150,
        },
        {
            headerName: '',
            field: 'tagName',
            cellRenderer: 'DeleteButtonCell',
            width: 50,
            cellStyle: { justifyContent: 'end' },
            cellRendererParams: {
                context: {
                    itemValue: true,
                    clicked(context) {
                        console.log('clicked', context);
                        editSettingsStore_1.editSettingsStore.commit.deleteOeffnungszeiten(context.row);
                    },
                },
            },
        },
    ];
}
exports.settingsPraxisColumnsDefs = settingsPraxisColumnsDefs;
