"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorBrightness = exports.hexToRgb = exports.rgbToHex = void 0;
function rgbToHex(red, green, blue) {
    const rgb = (red << 16) | (green << 8) | (blue << 0);
    return '#' + (0x1000000 + rgb).toString(16).slice(1);
}
exports.rgbToHex = rgbToHex;
function hexToRgb(hex) {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal)
        return normal.slice(1).map(e => parseInt(e, 16));
    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand)
        return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));
    return [0, 0, 0];
}
exports.hexToRgb = hexToRgb;
function colorBrightness(hex) {
    const [r, g, b] = hexToRgb(hex);
    return (r * 299 + g * 587 + b * 114) / 1000;
}
exports.colorBrightness = colorBrightness;
