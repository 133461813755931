"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPraxisTyp = exports.Zuordnungsart = void 0;
var Zuordnungsart;
(function (Zuordnungsart) {
    Zuordnungsart["PA"] = "pa";
    Zuordnungsart["HKP"] = "hkp";
})(Zuordnungsart || (exports.Zuordnungsart = Zuordnungsart = {}));
var IPraxisTyp;
(function (IPraxisTyp) {
    IPraxisTyp["none"] = "none";
    IPraxisTyp["single"] = "single";
    IPraxisTyp["multi"] = "multi";
})(IPraxisTyp || (exports.IPraxisTyp = IPraxisTyp = {}));
