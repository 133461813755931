"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.botInfoStore = void 0;
const direct_vuex_1 = require("direct-vuex");
const data_1 = require("@/services/data");
const settings_1 = require("@/helpers/settings");
const common_ui_1 = require("@rose/common-ui");
const lodash_1 = require("lodash");
const authStore_1 = require("@/state/authStore");
const botInfoState = {
    botInfo: {},
};
const { store, rootActionContext } = (0, direct_vuex_1.createDirectStore)({
    strict: process.env.NODE_ENV !== 'production',
    state: () => botInfoState,
    getters: {},
    mutations: {
        setBotInfo(state, botInfo) {
            state.botInfo = botInfo;
        },
    },
    actions: {
        async init(context) {
            const { state, commit } = rootActionContext(context);
            let botInfo = (0, settings_1.emptyBotData)();
            try {
                botInfo = await data_1.collectorApi.bot.bot();
            }
            catch (err) {
                console.error(`could not get bot info`, err);
            }
            if (botInfo === (0, settings_1.emptyBotData)() && common_ui_1.isDev) {
                botInfo = (0, settings_1.demoBotData)();
            }
            if ((0, lodash_1.isEmpty)(state.botInfo)) {
                console.log('initializing botInfoState', botInfo);
            }
            else {
                console.log('reset botInfoState', botInfo);
            }
            commit.setBotInfo(botInfo);
        },
        async updateExternalIp(context) {
            const { state, dispatch } = rootActionContext(context);
            try {
                const res = await data_1.collectorApi.bot.updateExternalIp(state.botInfo.cid);
                console.log('updateExternalIp', res);
                if (!res.success) {
                    await authStore_1.authStore.dispatch.notifyError(`IP-Adresse konnte nicht aktualisiert werden: ${res.error}`);
                    return;
                }
                void authStore_1.authStore.dispatch.notifySuccess(`IP-Adresse aktualisiert: ${res.ip}`);
                if (state.botInfo.ipExternal !== res.ip) {
                    await dispatch.init();
                }
            }
            catch (err) {
                void authStore_1.authStore.dispatch.notifyError(`IP-Adresse konnte nicht aktualisiert werden`);
            }
        },
    },
});
exports.botInfoStore = store;
