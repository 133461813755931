"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var praxis_1 = require("@/components/columns/settings/praxis");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: (0, praxis_1.settingsPraxisColumnsDefs)(),
      wochentage: common_ui_1.wochentage,
      wochentagSelected: {
        tag: '',
        von: '',
        bis: ''
      },
      showSave: false
    };
  },
  computed: {
    oeffnungszeiten: function oeffnungszeiten() {
      return editSettingsStore_1.editSettingsStore.getters.oeffnungszeiten;
    },
    showAdd: function showAdd() {
      return !!(this.wochentagSelected.tag && this.wochentagSelected.von && this.wochentagSelected.bis) && !this.bisValidator;
    },
    bisValidator: function bisValidator() {
      if (this.wochentagSelected.von && this.wochentagSelected.bis) {
        return this.wochentagSelected.von > this.wochentagSelected.bis;
      }

      return false;
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'single',
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    addOeffnungszeiten: function addOeffnungszeiten() {
      editSettingsStore_1.editSettingsStore.commit.addOeffnungszeiten(this.wochentagSelected);
      this.wochentagSelected = {
        tag: '',
        von: '',
        bis: ''
      };
    }
  }
});