/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"oeffnungszeiten-table"},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"grid-wrapper"},[_c('ag-grid-vue',{staticClass:"ag-theme-balham",staticStyle:{"width":"100%","height":"100%"},attrs:{"column-defs":_vm.columnDefs,"row-data":_vm.oeffnungszeiten,"locale-text":_vm.localeText,"grid-options":_vm.gridOptions},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellClicked}})],1)],1),_c(VContainer,{staticClass:"mt-4",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"col-md-4 col-sm-12"},[_c(VSelect,{attrs:{"items":_vm.wochentage,"label":"Wochentag","persistent-placeholder":"","placeholder":"Bitte wählen","item-text":"name","item-value":"id","hide-details":"","dense":""},model:{value:(_vm.wochentagSelected.tag),callback:function ($$v) {_vm.$set(_vm.wochentagSelected, "tag", $$v)},expression:"wochentagSelected.tag"}})],1),_c(VCol,{staticClass:"col-md-3 col-sm-12"},[_c(VTextField,{attrs:{"label":"Von","error":_vm.bisValidator,"type":"time","hide-details":"","dense":""},model:{value:(_vm.wochentagSelected.von),callback:function ($$v) {_vm.$set(_vm.wochentagSelected, "von", $$v)},expression:"wochentagSelected.von"}})],1),_c(VCol,{staticClass:"col-md-3 col-sm-12"},[_c(VTextField,{attrs:{"label":"Bis","error":_vm.bisValidator,"type":"time","hide-details":"","dense":""},model:{value:(_vm.wochentagSelected.bis),callback:function ($$v) {_vm.$set(_vm.wochentagSelected, "bis", $$v)},expression:"wochentagSelected.bis"}})],1),_c(VCol,{staticClass:"ml-auto pr-0",attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.showAdd,"small":""},on:{"click":function($event){return _vm.addOeffnungszeiten()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v("Hinzufügen")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }